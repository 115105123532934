<template>
  <div class="course-box">

    <el-card shadow="never">
      <h3 slot="header">PTE {{$t('考试介绍')}}</h3>
      <el-row :gutter="40">
        <el-col :span="8" v-for="(item, index) in $store.getters.getCourses('pte')" :key="index">
          <el-card class="margin-t img-item">
            <el-image class="item-img" @click="to(item)" slot="header" :src="$host + item.cover"
              style="width:100%;"></el-image>
            <h4 @click="to(item)">{{ item.title }}</h4>
          </el-card>

        </el-col>
      </el-row>

    </el-card>


    <el-card shadow="never" class="margin-lt">
      <div class="flex flex-v-center" slot="header">
        <h3 slot="header">{{$t('学习 PTE 入门课程')}}</h3>

        <el-menu :default-active="tag" class="margin-x" mode="horizontal" @select="handleSelect">
          <el-menu-item index="speaking">{{$t('口语')}}</el-menu-item>
          <el-menu-item index="writing">{{$t('写作')}}</el-menu-item>
          <el-menu-item index="reading">{{$t('阅读词汇')}}</el-menu-item>
          <el-menu-item index="listening">{{$t('听力')}}</el-menu-item>
        </el-menu>
      </div>

      <el-row :gutter="40">
        <el-col :span="8" v-for="(item, index) in $store.getters.getCourses(tag)" :key="index">
          <el-card class="margin-t img-item">
            <el-image @click="to(item)" slot="header" :src="$host + item.cover"></el-image>
            <h4 @click="to(item)">{{ item.title }}</h4>
          </el-card>

        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      tag: "speaking",
    }
  },

  created() {
    if (this.$store.state.courses.length == 0) {
      this.$http.post("/api/course/list").then((courses) => {
        this.$store.commit("setCourses", courses);
      })
    }
  },

  methods: {
    to(item) {
      this.$router.push("/media?tag=course&id=" + item.id)
    },

    handleSelect(index) {
      this.tag = index;
    },

    query_course() {
      this.$http.post("/api/course/list").then((res) => {
        this.list1 = res.list1;
        this.list2 = res.list2;
      })
    }
  },
};
</script>

<style scoped>
.course-box {
  margin: 40px 15%;
  position: relative;
}

.course-box>>>.el-card {
  border: none !important;
}

.course-box>>>.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none !important;
}

.course-box>>>.el-menu--horizontal>.el-menu-item {
  border-bottom: none !important;
}


.img-item>>>.el-image {
  height: 200px;
}

.img-item>>>.el-card__header {
  padding: 0 !important;
}
</style>
